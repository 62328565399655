<template>
    <div>
        <vs-popup class="holamundo popup-width company-details-modal" title="Visitor Details" :active.sync="consumerDetailPopup">
            <vs-button @click="closepopup" color="secondary" type="filled" class="mb-8 float-right close-popup-btn" :title="LabelConstant.buttonLabelClose">
                {{ LabelConstant.buttonLabelClose }}
            </vs-button>
            <template v-if="consumerDetailData && professionalDetails">
                <vx-card class="mb-base">
                    <h4 class="mt-4 mb-5">
                        {{ consumerName }}
                        <a :href="`https://www.${consumerDetail.Linkedin_url}`" target="_blank" v-if="consumerDetail.Linkedin_url!=''" class="mx-2">
                            <img height="25px" width="25px" class="lead-img" src="../../../assets/images/linkedin-icon.png"/>
                        </a>
                        <a :href="`https://www.${consumerDetail.Twitter_url}`" target="_blank" v-if="consumerDetail.Twitter_url!=''" class="mx-2">
                            <img height="25px" width="25px" class="lead-img" src="../../../assets/images/twitter-circle.png"/>
                        </a>
                        <a :href="`https://www.${consumerDetail.Facebook_url}`" target="_blank" v-if="consumerDetail.Facebook_url!=''" class="mx-2">
                            <img height="25px" width="25px" class="lead-img" src="../../../assets/images/facebook-icon.png"/>
                        </a>
                    </h4>
                    <vs-row vs-w="12">
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" vs-sm="12" class="mb-3" v-if="consumerDetail.Email">
                            <div class="icon-style mr-3">
                                <span class="material-icons"> mail_outline </span>
                            </div>
                            <div class="consumer-text">
                                <h5>Email Address</h5>
                                <p>{{ consumerDetail.Email }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" class="mb-3" vs-sm="12" v-if="consumerDetail.Physical_Address">
                            <div class="icon-style mr-3">
                                <feather-icon icon="MapPinIcon"></feather-icon>
                            </div>
                            <div class="consumer-text">
                                <h5>Physical Address</h5>
                                <p>{{ consumerDetail.Physical_Address }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" vs-sm="12" class="mb-3" v-if="sessions[0].Date_Created">
                            <div class="icon-style mr-3">
                                <feather-icon icon="CalendarIcon"></feather-icon>
                            </div>
                            <div class="consumer-text">
                                <h5>Date / Time</h5>
                                <p>{{ sessions[0].Date_Created }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" class="mb-3" vs-sm="12" v-if="sessions[0].Campaign_Source">
                            <div class="icon-style mr-3">
                                <feather-icon icon="LinkIcon"></feather-icon>
                            </div>
                            <div class="consumer-text">
                                <h5>Source and  Keywords Used</h5>
                                <p>{{ sessions[0].Campaign_Source }} : {{ sessions[0].Campaign_Term ? sessions[0].Campaign_Term :"No keyword information found"}}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" class="mb-3" vs-sm="12" v-if="consumerDetail.Gender">
                            <div class="icon-style mr-3">
                                <font-awesome-icon icon="venus-mars" />
                            </div>
                            <div class="consumer-text">
                                <h5>Gender</h5>
                                <p>{{ consumerDetail.Gender=='M'?"Male":"Female" }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" vs-sm="12" class="mb-3" v-if="consumerDetail.Age">
                            <div class="icon-style mr-3">
                                <span class="material-icons"> portrait </span>
                            </div>
                            <div class="consumer-text">
                                <h5>Estimated Age</h5>
                                <p>{{ consumerDetail.Age }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" vs-sm="12" class="mb-3" v-if="consumerDetail.Married">
                            <div class="icon-style mr-3">
                                <span class="material-icons"> supervised_user_circle </span>
                            </div>
                            <div class="consumer-text">
                                <h5>Marital Status</h5>
                                <p>{{ consumerDetail.Married }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" vs-sm="12" class="mb-3" v-if="consumerDetail.Home_Owner">
                            <div class="icon-style mr-3">
                                <span class="material-icons"> home </span>
                            </div>
                            <div class="consumer-text">
                                <h5>Own Home</h5>
                                <p>{{ consumerDetail.Home_Owner }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" class="mb-3" vs-sm="12" v-if="consumerDetail.Annual_Income">
                            <div class="icon-style mr-3">
                                <span class="material-icons"> attach_money </span>
                            </div>
                            <div class="consumer-text">
                                <h5>Estimated Annual Income</h5>
                                <p>{{ consumerDetail.Annual_Income }}</p>
                            </div>
                        </vs-col>
                        <vs-col vs-type="flex" vs-align="start" vs-lg="6" vs-sm="12" v-if="consumerDetail.Net_Worth">
                            <div class="icon-style mr-3">
                                <span class="material-icons"> attach_money </span>
                            </div>
                            <div class="consumer-text">
                                <h5>Estimated Networth</h5>
                                <p>{{ consumerDetail.Net_Worth }}</p>
                            </div>
                        </vs-col>
                    </vs-row>
                </vx-card>
                <vs-tabs class="mt-0">
                    <vs-tab label="Professional" v-if="professionalDetails.CompanyName">
                        <div class="con-tab-ejemplo" id="page-user-view">
                            <vx-card :title="`${ professionalDetails.CompanyName }`" class="mb-base">
                                <div class="vx-row mb-5">
                                    <!-- Company logo -->
                                    <div class="vx-col" id="avatar-col" v-if="professionalDetails.CompanyLogoUrl">
                                        <div class="img-container mb-4">
                                            <img :src="professionalDetails.CompanyLogoUrl" height="100" width="100" />
                                        </div>
                                    </div>
                                    <!-- Social icon, Company Website, Industry, Specialities -->
                                    <div class="vx-col flex-1" id="account-info-col-1">
                                        <!-- Social icon -->
                                        <div class="flex">
                                            <a :href="`https://www.${professionalDetails.CompanyLinkedInUrl}`" target="_blank" v-if="professionalDetails.CompanyLinkedInUrl">
                                                <vs-avatar color="primary" icon-pack="feather" icon="icon-linkedin" />
                                            </a>
                                        </div>
                                        <table>
                                            <!-- Company Website -->
                                            <tr>
                                                <td class="font-semibold"><feather-icon icon="GlobeIcon" title="Website"></feather-icon></td>
                                                <td>{{professionalDetails.CompanyDomain}}</td>
                                            </tr>
                                            <!-- Company Industry -->
                                            <tr>
                                                <td class="font-semibold"><feather-icon icon="LayersIcon"></feather-icon></td>
                                                <td class="word-break-all">{{ professionalDetails.CompanyIndustry }}</td>
                                            </tr>
                                            <!-- Company Specialities -->
                                            <tr>
                                                <td class="font-semibold"><feather-icon icon="TagIcon"></feather-icon></td>
                                                <td class="word-break-all">{{ professionalDetails.CompanySpecialties }}</td>
                                                <!-- Chemicals, Consumer Products, Energy, Engineering Products And Services, Information Technology And Communications, Materials, Services -->
                                            </tr>
                                        </table>
                                    </div>
                                    <!-- Number of Company Employee, Phone, Address, Revenue -->
                                    <div class="vx-col lg:w-1/2 md:w-3/3 sm:w-3/3 w-full lg:pt-0 pt-4" id="account-info-col-2">
                                        <table>
                                            <!-- Number of Company Employee -->
                                            <tr>
                                                <td class="font-semibold"><feather-icon icon="UsersIcon"></feather-icon></td>
                                                <td>{{ professionalDetails.CompanyEmployees | tableRecordNumberFormatter}}</td>
                                            </tr>
                                            <!-- Company Phone -->
                                            <!-- <tr>
                                                <td class="font-semibold"><feather-icon icon="PhoneIcon"></feather-icon></td>
                                                <td>{{ professionalDetails.CompanyPhone }}</td>
                                            </tr> -->
                                            <!-- Company Address -->
                                            <tr>
                                                <td class="font-semibold"><feather-icon icon="MapPinIcon"></feather-icon></td>
                                                <td class="word-break-all">{{ address }}</td>
                                            </tr>
                                            <!-- Company Revenue -->
                                            <tr>
                                                <td class="font-semibold"><feather-icon icon="DollarSignIcon"></feather-icon></td>
                                                <td>{{ professionalDetails.CompanyRevenueRange }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="professionalDetails.CompanyDescription">
                                    <h4 class="mt-4 mb-3">About {{ professionalDetails.CompanyName }}</h4>
                                    <b-card-text>{{ professionalDetails.CompanyDescription }}</b-card-text>
                                </div>
                            </vx-card>
                        </div>
                    </vs-tab>
                    <vs-tab label="Pages Viewed">
                        <div class="con-tab-ejemplo page-view tab-text">
                            <div class="tab-title">
                                <h3 class="py-2">Pages Viewed - Detailed</h3>
                                <!-- <span class="text-primary">{{ sessions.length }} Unique Visitors</span> -->
                                <!-- <vs-button type="filled" icon-pack="feather" icon="icon-filter" class="small-btn bg-third">Filters Visitors</vs-button> -->
                            </div>
                            <ul>
                                <li v-for="session in sessions" :key="session.Session_Id">
                                    <h4>Session: {{ session.Date_Created }}</h4>
                                    <!-- <span>Visitor Id: {{ session.Visitor_Id }}</span> -->
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Pages Viewed</th>
                                                <th width="130px">Time Spent</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="pageView in session.Page_Views" :key="pageView.Page_Url">
                                                <td>{{pageView.Page_Url }}</td>
                                                <td width="130px">{{ pageView.Time_Spent }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </li>
                            </ul>
                        </div>
                    </vs-tab>
                    <vs-tab label="Campaign Info">
                        <div class="tab-text page-view">
                            <div class="tab-title">
                                <h3>Campaign Information</h3>
                            </div>
                            <!-- <vs-table class="summary-table campaign-table">
                                <template>
                                    <vs-tr>
                                    <vs-th width="200">Campaign Name</vs-th>
                                    <vs-td>{{ consumerDetail.Campaign_Name }}</vs-td>
                                    </vs-tr>
                                    <vs-tr>
                                    <vs-th width="200">Campaign Source</vs-th>
                                    <vs-td>{{ consumerDetail.Campaign_Source }}</vs-td>
                                    </vs-tr>
                                    <vs-tr>
                                    <vs-th width="200">Campaign Term</vs-th>
                                    <vs-td>{{ consumerDetail.Campaign_Term }}</vs-td>
                                    </vs-tr>
                                    <vs-tr>
                                    <vs-th width="200">Campaign Content</vs-th>
                                    <vs-td>{{ consumerDetail.Campaign_Content }}</vs-td>
                                    </vs-tr>
                                    <vs-tr>
                                    <vs-th width="200">Campaign Medium</vs-th>
                                    <vs-td>{{ consumerDetail.Campaign_Medium }}</vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table> -->
                            <table  class="summary-table campaign-table mb-3 mt-2" style="border:1px solid #efefef; border-radius: 1em;">
                                <tbody>
                                    <tr>
                                        <th width="200">Campaign Name</th>
                                        <td>{{ consumerDetail.Campaign_Name }}</td>
                                    </tr>
                                    <tr>
                                        <th width="200">Campaign Source</th>
                                        <td>{{ consumerDetail.Campaign_Source }}</td>
                                    </tr>
                                    <tr>
                                        <th width="200">Campaign Term</th>
                                        <td>{{ consumerDetail.Campaign_Term }}</td>
                                    </tr>
                                    <tr>
                                        <th width="200">Campaign Content</th>
                                        <td>{{ consumerDetail.Campaign_Content }}</td>
                                    </tr>
                                    <tr>
                                        <th width="200">Campaign Medium</th>
                                        <td>{{ consumerDetail.Campaign_Medium }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="tab-text page-view mt-4">
                            <div class="tab-title">
                                <h3>Summary of Keywords</h3>
                            </div>
                            <vs-table class="summary-table"  :data="keywords">
                                <template slot="thead">
                                    <vs-th>Keyword</vs-th>
                                    <vs-th width="80">count</vs-th>
                                </template>
                                <template slot-scope="{ data }">
                                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                        <vs-td>{{ data[indextr].Keyword }}</vs-td>
                                        <vs-td width="80" align="center">{{ data[indextr].Keyword_Count }}</vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </template>
            <div class="text-right">
                <vs-button :title="LabelConstant.buttonLabelClose" color="grey" @click="closepopup" type="border" class="grey-btn mb-5">
                    {{ LabelConstant.buttonLabelClose }}
                </vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
export default {
    props:{
        consumerDetailPopup: {
            type: Boolean,
            required: true
        },
        consumerDetail:{
            required: true
        },
        consumerLeadId:{
            required: true
        }
    },
    data(){
        return{
           show:false,
        }
    },
    computed:{
        consumerDetailData:{
            get(){
                return this.consumerDetail
            }
        },
        professionalDetails:{
            get(){
                return this.consumerDetail?this.consumerDetail.ProfessionalDetails:{}
            }
        },
        sessions:{
            get(){
                return this.consumerDetail.Sessions
            }
        },
        keywords:{
            get(){
                return this.consumerDetail.Keywords
            }
        },
        consumerName:{
            get(){
                return this.consumerDetail?this.consumerDetail.First_Name +" "+ this.consumerDetail.Last_Name:""
            }
        },
        address:{
            get(){
                let consumerAddressArr = [];
                consumerAddressArr.push(this.professionalDetails.CompanyAddressLine1);
                consumerAddressArr.push(this.professionalDetails.CompanyAddressLine2);
                consumerAddressArr.push(this.professionalDetails.CompanyCity);
                consumerAddressArr.push(this.professionalDetails.CompanyRegion);
                consumerAddressArr.push(this.professionalDetails.CompanyCountry);
                let companyAddressFinal = "";
                for (let i = 0; i < consumerAddressArr.length; i++) {
                    if (consumerAddressArr[i] && consumerAddressArr[i].length > 0) {
                    companyAddressFinal += consumerAddressArr[i] + ", ";
                    }
                }
                companyAddressFinal = companyAddressFinal.substring(0,companyAddressFinal.length - 2);
                return this.professionalDetails?companyAddressFinal:""
            }
        },
    },
    methods:{
        closepopup(){
            this.$emit("close")
        },
    }
}
</script>
