<template>
  <div class="consumer-dashboard">
    <!-- Top Domain Details -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="domain-info mb-8">
          <!-- Domain -->
          <select-filter
            labelname="Domain"
            :options="domainOptions"
            optionsName="domainName"
            :value="domainSelected"
            @input="
              newDomain => {
                domainSelected = newDomain;
              }
            "
          ></select-filter>
          <!-- Date Picker -->
          <date-range-picker
            :fromDateValue="fromDate"
            :toDateValue="toDate"
            @inputFromDate="
              newFromDate => {
                fromDate = newFromDate;
              }
            "
            @inputToDate="
              newToDate => {
                toDate = newToDate;
              }
            "
          ></date-range-picker>
          <!-- Update Dashboard -->
          <vs-button type="filled" color="" class="small-btn bg-third" @click="updateDashboard()">
            {{ LabelConstant.buttonLabelUpdateDashboard }}
          </vs-button>
        </div>
      </div>
    </div>
    <!-- Identified Companies -->
    <div class="vx-row">
      <!-- Total Identified Visitors -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-0">
        <vx-card class="h-100">
          <div class="budget-title card-title">
            <span class="material-icons text-second"> supervisor_account </span>
            <!-- <h2>{{ totalIdentifiedVisitorsData?totalIdentifiedVisitorsData.StatName:"" }}</h2> -->
            <h2>Identified This Month</h2>
          </div>
          <h3 class="text-second">{{ totalIdentifiedVisitorsData ?totalIdentifiedVisitorsData.StatUsedValue:0 | tableRecordNumberFormatter }}</h3>
          <apexchart
            type="radialBar"
            height="260"
            :options="totalIdentifiedVisitorsChart.chartOptions"
            :series="totalIdentifiedVisitorsChart.series"
          ></apexchart>
          <div class="currently-budget">
            <span >Previous Period: {{ totalIdentifiedVisitorsData?totalIdentifiedVisitorsData.PerviousActual:0  | tableRecordNumberFormatter }}</span>
            <span class="border-top text-secondary">Budgeted Monthly Identifications: {{ totalIdentifiedVisitorsData?totalIdentifiedVisitorsData.StatTotalValue:0  | tableRecordNumberFormatter }}</span>
            <span class="mt-2 text-sm text-danger"><router-link class="text-underline text-danger" to="/consumer/configuration/known-customers/uid/0/">Known Customers & Associates</router-link> are not counted in the number above.</span>
          </div>
        </vx-card>
      </div>
      <!-- Monthly Spend -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-1">
        <vx-card>
          <div class="card-title budget-title">
            <span class="material-icons text-first"> monetization_on </span>
            <!-- <h2>{{ totalSpendData?totalSpendData.StatName:"" }}</h2> -->
            <h2>Monthly Spend</h2>
          </div>
          <h3 class="text-first">${{ totalSpendData?totalSpendData.StatUsedValue:0 | tableRecordNumberFormatter }}</h3>
          <apexchart
            type="radialBar"
            height="260"
            :options="totalSpendChart.chartOptions"
            :series="totalSpendChart.series"
          ></apexchart>
          <div class="currently-budget">
            <span></span>
            <!-- <span>Current Budget: ${{ totalSpendData?totalSpendData.PerviousActual:0 }}</span>  -->
             <span class="border-top text-secondary">Current Monthly Budget: ${{ totalSpendData?totalSpendData.StatTotalValue:0 | tableRecordNumberFormatter }}</span>
          </div>
        </vx-card>
      </div>
      <!-- Latest Visitorss -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6" id="v-step-2">
        <vx-card class="main-list h-full">
          <div class="card-title">
            <h2 class="w-auto">Latest Visitors</h2>
             <router-link
             class="view-option text-second"
              :to="{ name: 'ConsumerSearchLeads', params: { uid: uid } }"
              >View All</router-link
            >
          </div>
          <ul class="latest-list mb-0">
            <li v-for="(visitor, index) of latestVisitors" :key="index">
              <a>{{ visitor.Name }}</a>
              <span class="date">{{ visitor.Date_Last_Visited }}</span>
              <a class="go-link text-third" @click="consumerDetailPopup=true,getLeadDetail(visitor.Id)">
                <feather-icon icon="EyeIcon"></feather-icon>
              </a>
            </li>
            <p v-if="latestVisitors.length==0" class="text-sm">No latest visitors for this date range.</p>
          </ul>
        </vx-card>
      </div>
      <!-- Direct vs. Referral -->
      <div class="vx-col xl:w-1/4 mb-8 md:w-1/2 w-full lg-cus-6">
        <vx-card class="h-full">
          <div class="card-title more-card-title">
            <h2>Direct vs. Referral</h2>
          </div>
          <div class="contact-found pie-chart">
          <apexchart
            ref="directVsReferralRef"
            type="pie"
            height="320"
            :options="directVsReferralChart.chartOptions"
            :series="directVsReferralChart.series"
          ></apexchart>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
       <!-- Recent Visits -->
      <div class="vx-col xl:w-6/12 col-cus-12 mb-8 w-full">
        <vx-card class="h-full">
          <div class="card-title more-card-title">
            <h2>Recent Visits</h2>
          </div>
          <GmapMap
            :center="center"
            :zoom="2"
            map-type-id="terrain"
            style="height: 268px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :title="m.infoText"
              :clickable="true"
            />
          </GmapMap>
        </vx-card>
      </div>
      <!-- Visitors Identified -->
      <div class="vx-col xl:w-6/12 col-cus-12 mb-8 w-full">
        <vx-card class="h-full">
          <div class="card-title">
            <h2>{{ LabelConstant.labelVisitorsIdentified }}</h2>
          </div>
          <apexchart
            ref="visitorsIdentifiedRef"
            type="line"
            height="270"
            :options="visitorsIdentifiedChart.chartOptions"
            :series="visitorsIdentifiedChart.series"
          ></apexchart>
        </vx-card>
      </div>
    </div>
    <div class="vx-row">
      <!-- Gender -->
      <div class="vx-col xl:w-3/12 lg-cus-6 mb-8 w-full">
        <vx-card class="h-full">
          <div class="card-title more-card-title">
            <h2>Gender</h2>
          </div>
          <div class="contact-found pie-chart">
          <apexchart
            ref="genderRef"
            type="pie"
            height="320"
            :options="genderChart.chartOptions"
            :series="genderChart.series"
          ></apexchart>
          </div>
        </vx-card>
      </div>
      <!-- income level -->
      <div class="vx-col xl:w-3/12 lg-cus-6 mb-8 w-full">
        <vx-card class="h-full">
          <div class="card-title">
            <h2>Income Level</h2>
          </div>
          <apexchart
            ref="incomeLevelRef"
            type="bar"
            height="250"
            :options="incomeLevelChart.chartOptions"
            :series="incomeLevelChart.series"
          ></apexchart>
        </vx-card>
      </div>
      <!-- Age Range -->
      <div class="vx-col xl:w-3/12 lg-cus-6 mb-8 w-full">
        <vx-card class="h-full">
          <div class="card-title">
            <h2>Age Range</h2>
          </div>
          <apexchart
            ref="ageRangeRef"
            type="bar"
            height="250"
            :options="ageRangeChart.chartOptions"
            :series="ageRangeChart.series"
          ></apexchart>
        </vx-card>
      </div>
      <!-- Location -->
      <div class="vx-col xl:w-3/12 lg-cus-6 mb-8 w-full">
        <vx-card class="h-full">
          <div class="card-title">
            <h2>Location</h2>
          </div>
          <apexchart
            ref="locationRef"
            type="bar"
            height="250"
            :options="locationChart.chartOptions"
            :series="locationChart.series"
          ></apexchart>
        </vx-card>
      </div>
    </div>
    <consumer-lead-detail-modal
      :consumerDetailPopup="consumerDetailPopup"
      @close="closepopup"
      :consumerLeadId="consumerLeadId"
      :consumerDetail="consumerDetail"
    ></consumer-lead-detail-modal>
  </div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
/* Filter */
import SelectFilter from "../../../components/filter/SelectFilter";
import DateRangePicker from "../../../components/filter/DateRangePicker";
import OnlyCompany from "../../../components/filter/OnlyCompany";
import HelpModal from "../../../components/modal/HelpModal";
import CompanyDetailsModal from "../../../components/modal/CompanyDetailsModal";
import SingleCard from "@/components/SingleCard";
import ConsumerLeadDetailModal from "@/components/modal/consumer/ConsumerLeadDetailModal";
/* chart */
import VueApexCharts from "vue-apexcharts";

export default {
  title: "Dashboard",
  components: {
    VxCard,
    SelectFilter,
    DateRangePicker,
    OnlyCompany,
    HelpModal,
    CompanyDetailsModal,
    SingleCard,
    apexchart: VueApexCharts,
    ConsumerLeadDetailModal
  },
  data() {
    let uid = this.$route.params.id;
    let VVtoken = localStorage.getItem("VVtoken");
    let token = JSON.parse(VVtoken);
    return {
      uid: this.$route.params.id,
      // VV Tour
      myOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: "Skip tour",
          buttonPrevious: "Previous",
          buttonNext: "Next",
          buttonStop: "Next Page",
        },
      },

      /* Filter Select */
      domainSelected: { domainName: "All" },
      //Domain dropdown options
      domainOptions: [],

      //From Date
      fromDate: null,
      //To Date
      toDate: null,

      //Latest Visitors List
      latestVisitors: [],

      //Recent Visits Map
      center: { lat: 41, lng: -87 },
      //Store details for Map
      markers: [],
      // Monthly Spend
      totalSpendData:null,
      totalSpendChart:{
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              startAngle: 0,
              dataLabels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 10,
                  formatter: function (val) {
                    if(val==0 || val==Infinity){
                      return '--%';
                    }
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
                  },
                },
              },
            },
          },
          colors: [token[uid].themeDetail.tempChartColor1],
        },
      },
      // Total Identified Visitors
      totalIdentifiedVisitorsData:null,
      totalIdentifiedVisitorsChart:{
        series: [],
        chartOptions: {
          chart: {
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              startAngle: 0,
              dataLabels: {
                show: true,
                name: {
                  show: false,
                },
                value: {
                  show: true,
                  fontSize: "16px",
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 10,
                  formatter: function (val) {
                    if(val==0 || val==Infinity){
                      return '--%';
                    }
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
                  },
                },
              },
            },
          },
          colors: [token[uid].themeDetail.tempChartColor3],
        },
      },
      visitorsIdentifiedChart:{
        series: [
          {
            name: "Identified Visitors",
            type: "column",
            data: [],
          },
        ],
        chartOptions: {
          colors: [token[uid].themeDetail.tempChartColor1],
          chart: {
            type: "line",
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: [0, 2, 2],
            curve: "smooth",
          },
          plotOptions: {
            bar: {
              columnWidth: "45%",
            },
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          yaxis:{
            labels: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          labels: [],
          legend: {
            show: false,
            showForSingleSeries: true,
            markers: {
              radius: 0,
            },
            itemMargin: {
              horizontal: 5,
              vertical: 10,
            },
          },
          markers: {
            size: 0,
          },
          tooltip: {
            shared: false,
            intersect: false,
            y: {
              formatter: function (val) {
                if (typeof val !== "undefined") {
                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                return val;
              },
            },
          },
        },
      },
      genderChart:{
        series: [],
        chartOptions: {
          chart: {
            type: "pie",
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: "bottom",
            markers: {
              radius: 0,
            },
          },
          colors: [token[uid].themeDetail.tempChartColor1, token[uid].themeDetail.tempChartColor3],
          labels: [],
          responsive: [
            {
              breakpoint: 480,
            },
          ],
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
        },
      },
      directVsReferralChart:{
        series: [],
        chartOptions: {
          chart: {
            type: "pie",
          },
          legend: {
            show: true,
            showForSingleSeries: true,
            position: "bottom",
            markers: {
              radius: 0,
            },
          },
          colors: [token[uid].themeDetail.tempChartColor1, token[uid].themeDetail.tempChartColor3],
          labels: [],
          responsive: [
            {
              breakpoint: 480,
            },
          ],
          tooltip: {
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
        },
      },
      locationChart:{
        series: [
          {
            name:"Location",
            data: [],
          },
        ],
        chartOptions: {
          colors: [token[uid].themeDetail.tempChartColor1],
          chart: {
            type: "bar",
            height: 250,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            offsetX: 30,
            style: {
              fontSize: "12px",
              colors: ["#bbbaba"],
            },
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
            categories: [],
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      netWorthChart:{
        series: [
          {
            name:"New Worth",
            data: [],
          },
        ],
        chartOptions: {
          colors: [token[uid].themeDetail.tempChartColor1],
          chart: {
            type: "bar",
            height: 250,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            style: {
              offsetX: 30,
              fontSize: "12px",
              colors: ["#bbbaba"],
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
          },
          xaxis: {
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
            categories: [],
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      incomeLevelChart:{
        series: [
          {
            name:"Income Level",
            data: [],
          },
        ],
        chartOptions: {
          colors: [token[uid].themeDetail.tempChartColor1],
          chart: {
            type: "bar",
            height: 250,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            offsetX: 30,
            style: {
              fontSize: "12px",
              colors: ["#bbbaba"],
            },
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
            categories: [],
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      ageRangeChart:{
        series: [
          {
            name:"Age Range",
            data: [],
          },
        ],
        chartOptions: {
          colors: [token[uid].themeDetail.tempChartColor1],
          chart: {
            type: "bar",
            height: 250,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
              },
            },
          },
          grid: {
            show: false,
          },
          dataLabels: {
            enabled: true,
            offsetX: 30,
            style: {
              fontSize: "12px",
              colors: ["#bbbaba"],
            },
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
          },
          stroke: {
            show: true,
            width: 1,
            colors: ["#fff"],
          },
          tooltip: {
            shared: true,
            intersect: false,
            y: {
              formatter: function (val) {
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              },
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
            },
            categories: [],
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
      },
      // Consumer Detail Pop up
      consumerDetailPopup:false,
      consumerDetail: null,
      consumerLeadId:null,
    };
  },
  created() {
    if (this.$route.params.reload) {
      location.reload();
    }
    this.getDomainList();
    this.setFromDate();
    this.setToDate();
    this.loadChartData();
  },
  methods: {
    updateDashboard(){
      this.removeChartdata();
      this.loadChartData();
    },
    removeChartdata(){
      this.totalSpendChart.series=[];
      this.totalIdentifiedVisitorsChart.series=[];

      this.visitorsIdentifiedChart.series[0].data=[];
      this.locationChart.series[0].data=[];
      this.netWorthChart.series[0].data=[];
      this.ageRangeChart.series[0].data=[];
      this.incomeLevelChart.series[0].data=[];
      this.genderChart.series = [];
      this.directVsReferralChart.series = [];

      this.visitorsIdentifiedChart.chartOptions.labels = [];
      this.locationChart.chartOptions.xaxis.categories = [];
      this.netWorthChart.chartOptions.xaxis.categories = [];
      this.ageRangeChart.chartOptions.xaxis.categories = [];
      this.incomeLevelChart.chartOptions.xaxis.categories = [];
      this.genderChart.chartOptions.labels = [];
      this.directVsReferralChart.chartOptions.labels = [];

      // refresh Charts
      this.$refs.visitorsIdentifiedRef.refresh();
      this.$refs.locationRef.refresh();
      this.$refs.ageRangeRef.refresh();
      this.$refs.incomeLevelRef.refresh()
      this.$refs.genderRef.refresh();
      this.$refs.directVsReferralRef.refresh();
    },
    loadChartData(){
      this.getTotalSpend();
      this.getTotalIdentifiedVisitors()
      this.getLatestVisitorList();
      this.getRecentVisits();
      this.getDirectVSReferralVisitors();
      this.getVisitorsIdentifiedData();
      this.getIncomeLevelData();
      this.getLocationData();
      this.getGenderData();
      this.getAgeRangeData();
    },
    // Get Domain
    getDomainList() {
      this.$vs.loading();
      this.axios
        .get("/ws/ConsumerDashboard/GetDomainList")
        .then(response => {
          let data = response.data;

          for (let i = 0; i < data.length; i++) {
            this.domainOptions.push({
              domainName: data[i]
            });
          }

          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // Monthly Spend
    getTotalSpend(){
      this.$vs.loading();
      this.axios
        .get("/ws/ConsumerDashboard/GetTotalSpendInfo")
        .then(response => {
          let data = response.data
          let perc = 0;
          perc = ((data.StatUsedValue/data.StatTotalValue) * 100);
          this.totalSpendChart.series = [this.percentFormatter(Math.ceil(perc))];

          this.totalSpendData = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // Total Identified Visitors
    getTotalIdentifiedVisitors(){
      this.$vs.loading();
      this.axios
        .get("/ws/ConsumerDashboard/GetTotalIdentifiedVisitorsInfo")
        .then(response => {
          let data = response.data
          let perc = 0;
          perc = ((data.StatUsedValue/data.StatTotalValue) * 100);
          this.totalIdentifiedVisitorsChart.series = [this.percentFormatter(Math.ceil(perc))];
          this.totalIdentifiedVisitorsData = data;
          this.$vs.loading.close();
        })
        .catch(e => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getLatestVisitorList() {
      this.$vs.loading();
       let getLastestVisitorsParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.axios.post("ws/ConsumerDashboard/GetLatestVisitorsList",getLastestVisitorsParams)
        .then((response) => {
          let data = response.data;
          this.latestVisitors = data.ConsumerLeads;
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getRecentVisits() {
      let getLastestVisitorsParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/ConsumerDashboard/GetRecentVisitLocationsGeoData",getLastestVisitorsParams)
        .then((response) => {
          let data = response.data;
          this.markers = [];
          for (var i = 0; i < data.length; i++) {
            this.markers.push({
              position: {
                lat: parseFloat(data[i].Visitor_Lat),
                lng: parseFloat(data[i].Visitor_Long),
              },
              infoText:
                data[i].VisitorName +
                " - " +
                data[i].Visitor_City +
                ", " +
                data[i].Visitor_State +
                " " +
                data[i].Visitor_Country,
            });
          }
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    async getDirectVSReferralVisitors() {
      let getDirectReferralParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      await this.axios.post("/ws/ConsumerDashboard/GetDirectVsReferralGraphData",getDirectReferralParams)
        .then((response) => {
          let data = response.data;
          let x = [];
          if(data.GraphData.length){
            data.GraphData.forEach(data => {
              x.push(data.Key);
              this.directVsReferralChart.series.push(data.Value);
            });
            this.directVsReferralChart.chartOptions = {
              ...this.directVsReferralChart.chartOptions,
              labels: x,
            };
          }
          this.$refs.directVsReferralRef.refresh();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getVisitorsIdentifiedData() {
      let getVisitorIdentifiedParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios
        .post(
          "/ws/ConsumerDashboard/GetVisitorsIdentifiedGraphData",getVisitorIdentifiedParams)
        .then((response) => {
          let data = response.data;
          this.visitorsIdentifiedChart.series[0].data = [];
          if(data.GraphData.length){
            let x = [];
            data.GraphData.forEach(data => {
              x.push(data.Key);
              this.visitorsIdentifiedChart.series[0].data.push(data.Value);
            });
            this.visitorsIdentifiedChart.chartOptions.labels;
            this.visitorsIdentifiedChart.chartOptions = {
              ...this.visitorsIdentifiedChart.chartOptions,
              labels: x,
            };
          }
          this.$refs.visitorsIdentifiedRef.refresh();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getIncomeLevelData(){
      let getIncomeLevelParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/ConsumerDashboard/GetIncomeLevelGraphData",getIncomeLevelParams)
        .then((response) => {
          let data = response.data;
          this.incomeLevelChart.series[0].data = [];
          let x = [];
          if(data.GraphData.length){
            data.GraphData.forEach(data => {
              x.push(data.Key);
              this.incomeLevelChart.series[0].data.push(data.Value);
            });
            this.incomeLevelChart.chartOptions = {
              ...this.incomeLevelChart.chartOptions,
              ...
              {
                xaxis: {
                  categories: x
                }
              }
            };
          }
          this.$refs.incomeLevelRef.refresh();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getLocationData(){
        let getLocationParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/ConsumerDashboard/GetLocationGraphData",getLocationParams)
        .then((response) => {
          let data = response.data;
          let x = [];
          this.locationChart.series[0].data =[];
          if(data.GraphData.length){
            data.GraphData.forEach(data => {
              x.push(data.Key);
              this.locationChart.series[0].data.push(data.Value);
            });
            this.locationChart.chartOptions = {
              ...this.locationChart.chartOptions,
              ...
              {
                xaxis: {
                  categories: x
                }
              }
            };
          }
          this.$refs.locationRef.refresh();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getGenderData(){
      let getGenderParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/ConsumerDashboard/GetGenderGraphData",
          getGenderParams
        )
        .then((response) => {
          let data = response.data;
          let x = [];
          if(data.GraphData.length){
            data.GraphData.forEach(data => {
              x.push(data.Key);
              this.genderChart.series.push(data.Value);
            });
            this.genderChart.chartOptions.labels;
            this.genderChart.chartOptions = {
              ...this.genderChart.chartOptions,
              labels: x,
            };
          }
          this.$refs.genderRef.refresh();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    getAgeRangeData(){
      let getAgeRangeParams = {
        Domain:this.domainSelected.domainName,
        Date_End: this.toDate,
        Date_Start: this.fromDate,
      };
      this.$vs.loading();
      this.axios
        .post("/ws/ConsumerDashboard/GetAgeRangeGraphData",getAgeRangeParams)
        .then((response) => {
          let data = response.data;
          let x = [];
          this.ageRangeChart.series[0].data = [];
          if(data.GraphData.length){
            data.GraphData.forEach(data => {
              x.push(data.Key);
              this.ageRangeChart.series[0].data.push(data.Value);
            });
            this.ageRangeChart.chartOptions = {
              ...this.ageRangeChart.chartOptions,
              ...
              {
                xaxis: {
                  categories: x
                }
              }
            };
          }
          this.$refs.ageRangeRef.refresh();
          this.$vs.loading.close();
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.showError(e);
        });
    },
    // open lead detail
    getLeadDetail(id){
      this.consumerLeadId=id
      this.consumerDetail={}
      this.$vs.loading();
      this.axios
        .get("/ws/ConsumerLeads/GetConsumerLeadDetail?visitorId="+id)
        .then((response) => {
        let data = response.data;
        this.$vs.loading.close();
        this.consumerDetail=data
      })
      .catch((e) => {
      this.$vs.loading.close();
      this.$vs.notify({
        title: "Error",
          text: e.response.data.Errors[0].Message,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
          time: 4000,
      });
      this.showError(e);
      });
    },
    // close lead detail pop up
    closepopup(){
      this.consumerDetailPopup=false
    },
  },
};
</script>
